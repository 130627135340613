import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import BackArrow from './BackArrow';

const Home = () => {
  return (
    <div>
      <div className="hero">
        <h2>Put overthinking to great use</h2>
        <span>
          Start writing things down        <br />
          tag them by people, events, actions, feelings, location.
          <br />
          Don't just let things be in your head.
          <br />
          Go over them when you have the time.
        </span>
        <div className="download-link">
          <Link to="/download" className="download-button">
            Download and Clear my mind
          </Link>
        </div>
        

        <a href="https://www.producthunt.com/posts/chitraan?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chitraan" target="_blank">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=643005" alt="Chitraan - Put&#0032;overthinking&#0032;to&#0032;great&#0032;use | Product Hunt" style={{
          width: '250px', height: '54px', marginTop: '30px'
        }}  />
        </a>
      </div>

      <div className="screenshots">
        <img src={require('../assets/chitraan_ss.png')} alt="Screenshot 1" />
        {/* <img src={require('../assets/IMG_6947.PNG')} alt="Screenshot 2" />
        <img src={require('../assets/IMG_6948.PNG')} alt="Screenshot 4" />
        <img src={require('../assets/IMG_6949.PNG')} alt="Screenshot 5" />
        <img src={require('../assets/IMG_6950.PNG')} alt="Screenshot 6" /> */}
      </div>

      <footer className="footer">
        <ul>
          <li>
            <Link to="/privacypolicy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/support">
              Support
            </Link>
          </li>
          <li>
            <Link to="/mobile/changelog">
              Changelog
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Home;
