import React from 'react';
import './App.css';
import { createBrowserRouter, Link, NavLink, RouterProvider } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount';
import MobileChangelog from './components/MobileChangelog';
import Download from './components/Download';
import Support from './components/Support';
import Faqs from './components/Faqs';
import UserGuide from './components/UserGuide';




const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  
  },
  {
    path: "/deleteAccount",
    element: <DeleteAccount />,
  
  },
  {
    path: "/mobile/changelog",
    element: <MobileChangelog />,
  
  },
  {
    path: "/download",
    element: <Download />,
  
  },
  {
    path: "/support",
    element: <Support />,
  
  },
  {
    path: "/support/faqs",
    element: <Faqs />,
  
  },
  {
    path: "/support/user-guide",
    element: <UserGuide />,
  
  },

]);





const App = () => {
  return (
    <div >
   
  
  <nav className="navbar">
  <a href='/' className='logo-a'>
        <div className="brand">
     
          <img src="/assets/logo.png" alt="Chitraan Logo" className="logo" />
          <div id='headertext'>
          <h1>Chitraan</h1>
          <span className="tagline">think clearly</span>
          </div>
         
  
        </div>
        </a>
        
      </nav>

    <RouterProvider router={router} />

    </div>
  );
};

export default App;
